header {
  /* position: relative; */
  z-index: 10; /* Ensure header is on top */

  /* Add any other necessary styles */
}

.faqSection {
  margin-top: 100px; /* Adjust this value as needed */
  padding-top: 20px; /* Additional spacing if needed */
  z-index: 5; /* Ensure FAQ section is below the header */
}

.containeroxNew {
  width: 80%;
  max-width: 1920px;
  margin: 0px auto;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px; /* Adjust the max width as needed */
  margin: 0 auto;
  background-color: #f7f7f7;
}
