* {
  box-sizing: border-box;
}
.bodyBox {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
}
.carousel-fade {
  height: 100vh;
  overflow: hidden;
}
.carousel-fade .carousel-item {
  height: 100vh;
}
.carousel-fade .carousel-item img {
  width: 100% !important;
}
.carousel-caption {
  bottom: 5% !important;
  text-align: left !important;
  left: 10% !important;
  right: 10%;
  width: 80%;
}
.carousel-caption h5 {
  font-weight: 600;
  font-size: 36px;
}
.carousel-caption h5 span {
  font-weight: 200;
}
.navlanding {
  width: 100%;
  margin: 0px auto;
  height: 60px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
}
.image-container {
  height: 100%;
}
.navlanding .container {
  width: 90%;
  height: 60px;
  max-width: 1920px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
}

.navlanding li {
  list-style: none;
  display: inline-flex;
  color: white;
  font-size: 14px;
  margin: 0px 4px;
  padding: 8px 14px;
  cursor: pointer;
}

.containerox {
  width: 80%;
  max-width: 1920px;
  margin: 0px auto;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px; /* Adjust the max width as needed */
  margin: 0 auto;
  /* background-color: #f7f7f7; */
}

.info-contanier {
  width: 380px;
  padding: 24px 28px;
  background: linear-gradient(to bottom right, #0071ec, #0038cd);
  border-radius: 15px;
  box-sizing: border-box;
  position: relative;
}

.devider {
  width: 100%;
  height: 1px;
  margin: 34px 0px;
}
.input-containerLanding input {
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  color: #fff;
}
.input-containerLanding fieldset {
  border-color: #ccc !important;
}
.input-container {
  width: 360px;
  display: block;
}

.landingfield {
  width: 340px;
  height: 44px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid rgb(255, 255, 255, 0.4);
  margin: 6px 0px;
  background: none;
  padding: 0px 22px;
  box-sizing: border-box;
  outline: none;
  border-radius: 4px;
}
.landingfield:hover {
  border: 1px solid rgb(255, 255, 255, 0.75);
}

/* ::placeholder {
    color: white;
    opacity: 0.75; 
} */

.buttonlanding {
  width: 340px;
  height: 44px;
  color: #0038cd;
  font-size: 14px;
  font-weight: 600;
  border: none;
  margin: 8px 0px;
  box-sizing: border-box;
  outline: none;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}
.bg-theme-1 {
  position: absolute;
  top: 70%;
  left: 22%;
  width: 160px;
  height: 160px;
  border-radius: 15px;
  opacity: 0.3;
}

.bg-theme-1:hover {
  scale: 1.1;
}

.bg-theme-2 {
  position: absolute;
  top: -10%;
  left: 22%;
  opacity: 0.5;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
}

footer .container {
  width: 90%;
  max-width: 1920px;
  padding: 0px 24px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

footer .container p {
  font-size: 11px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  color: #c2caf2;
}
.input-container input::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}

.input-container::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #fff;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background-color: gray;
  border-radius: 50%;
  padding: 10px;
}

.custom-prev {
  left: 20px;
}

.custom-next {
  right: 20px;
}
.carousel-control-prev {
  padding: 1px;
}

.custom-arrow svg {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.registerbtn {
  width: 100%;
  /* margin: 0px auto; */
  margin-top: 41%;
  box-sizing: border-box;
  margin-left: 135px;
  /* position: fixed; */
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
}
@media (max-width: 991px) {
  .info-contanier,
  .input-container {
    width: 100%;
    margin-bottom: 20px;
  }
  .containerox {
    display: block;
    position: relative;
    top: 120px;
    left: 0;
    transform: translate(0%, 0%);
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .bodyBox {
    height: auto;
    min-height: 100vh;
  }
  .navlanding .container {
    width: 100%;
    padding: 0px 14px;
    background-color: #aeaeae;
  }
  .navlanding li {
    padding: 4px 5px;
  }
  .landingfield,
  .buttonlanding {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .navlanding {
    height: auto;
  }
  .navlanding .containerox {
    display: flex;
    top: 0;
  }
  footer {
    position: relative;
    height: 70px;
  }
  footer .container {
    display: block;
  }
  .containerox.mainarea {
    position: static;
    padding-top: 120px;
  }
  .bg-theme-1,
  .bg-theme-2 {
    display: none;
  }
}

.input-container input:-webkit-autofill {
  -webkit-text-fill-color: #fff;
}
.push-left {
  display: none;
}
.carousel-caption p {
  font-size: 17px;
}
@media (max-width: 991px) {
  .carousel-fade .carousel-item img {
    height: 100%;
    width: auto !important;
    object-fit: cover;
  }
  .carousel-caption h5 {
    font-size: 26px;
  }
  .carousel-caption p {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .navlanding .container {
    padding: 0 14px;
  }

  .navlanding li {
    display: block;
    padding: 10px 16px; /* Adjust padding for touch targets */
  }
  .push-left {
    display: block;
  }
}
