.overFlowModal .MuiDialog-paper {
  overflow: visible;
  border-radius: 6px !important;
  height: auto;
  max-width: 700px;
  max-height: 94vh;
  background-color: #fff !important;
  border-top: solid 10px #168e91;
}

.overFlowModalemail .MuiDialog-paper {
  overflow: visible;
  border-radius: 6px !important;
  height: auto;
  background-color: #fff !important;
}
.overFlowModal fieldset {
  border-color: rgb(145 158 171 / 75%) !important;
}
.overFlowModal .MuiDialog-container {
  overflow: auto !important;
}
.overFlowModal input {
  color: #080606 !important;
}

.overFlowModal label {
  color: #b8b8b8 !important;
}
.menuPopup .MuiMenu-paper {
  width: 100%;
  margin-top: 16px;
  border-radius: 0px;
  background-color: #606060;
  color: #fff !important;
  box-shadow: 0 0 2px 0 rgb(18 23 28 / 24%),
    -20px 20px 40px -4px rgb(0 0 0 / 24%);
}
.image-container {
  position: relative;
  display: inline-block; /* Ensures the container wraps around the image */
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

@media only screen and (max-width: 992px) {
  .overFlowModal .MuiDialog-paper {
    max-width: 94%;
    overflow-y: auto;
  }
}
